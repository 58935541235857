/*--------------------------------------------------------------
# Profile image overlay
--------------------------------------------------------------*/

.profile-overlay {
  position: absolute;
  top: 17px;
  bottom: 0;
  left: 166px;
  right: 0;
  border-radius: 50%;
  width: 33%;
  height: 28%;
  opacity: 0;
  transition: 0.3s ease;
  background-color: #06060670;
}

@media (min-width: 260px) and (max-width: 362px) {
  .profile-overlay {
    position: absolute;
    top: 17px;
    bottom: 0;
    left: 116px;
    right: 0;
    border-radius: 50%;
    width: 32%;
    height: 20%;
    opacity: 0;
    transition: 0.3s ease;
    background-color: #06060670;
  }
  .modal-header .profile-overlay {
    opacity: 1;
  }
}

@media (min-width: 365px) and (max-width: 400px) {
  .profile-overlay {
    position: absolute;
    top: 17px;
    bottom: 0;
    left: 122px;
    right: 0;
    border-radius: 50%;
    width: 32%;
    height: 21%;
    opacity: 0;
    transition: 0.3s ease;
    background-color: #06060670;
  }
  .modal-header .profile-overlay {
    opacity: 1;
  }
}

@media (min-width: 414px) and (max-width: 500px) {
  .profile-overlay {
    position: absolute;
    top: 17px;
    bottom: 0;
    left: 134px;
    right: 0;
    border-radius: 50%;
    width: 32%;
    height: 23%;
    opacity: 0;
    transition: 0.3s ease;
    background-color: #06060670;
  }
  .modal-header .profile-overlay {
    opacity: 1;
  }
}

@media (min-width: 501px) and (max-width: 900px) {
  .profile-overlay {
    position: absolute;
    top: 17px;
    bottom: 0;
    left: 167px;
    right: 0;
    border-radius: 50%;
    width: 33%;
    height: 28%;
    opacity: 0;
    transition: 0.3s ease;
    background-color: #06060670;
  }
  .modal-header .profile-overlay {
    opacity: 1;
  }
}
/* @media(min-width:540px) {
      .profile-overlay {
          position: absolute;
          top: 17px;
          bottom: 0;
          left: 174px;
          right: 0;
          border-radius: 50%;
          width: 33%;
          height: 28%;
          opacity: 0;
          transition: .3s ease;
          background-color: #06060670;
      }
      .modal-header .profile-overlay {
          opacity: 1;
      }
  } */

.modal-header:hover .profile-overlay {
  opacity: 1;
}

.icon {
  color: white;
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.fa-user:hover {
  color: #eee;
}
/*--------------------------------------------------------------
# Profile edit button
--------------------------------------------------------------*/

.edit_btn:focus {
  box-shadow: none !important;
  outline: 0px !important;
}

.edit_btn {
  width: 40%;
  background: #000000 !important;
  color: white !important;
  border-radius: 20px;
  font-family: "Montserrat";
}

.edit_btn:hover {
  border: 2px solid white;
}
/*--------------------------------------------------------------
# Profile form
--------------------------------------------------------------*/

.profile {
  border-radius: 50%;
  width: 35%;
  height: 35%;
}

::placeholder {
  color: black !important;
  opacity: 1;
  font-family: "Montserrat";
}

.input_user:focus,
.input_user:hover,
.input_user:active,
.input_pass:focus,
.input_pass:active,
.input_pass:hover {
  border: 2px solid black !important;
}

.input_user,
.input_pass:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
/*--------------------------------------------------------------
# Setting chnage password button
--------------------------------------------------------------*/

.changepassword_btn:focus {
  box-shadow: none !important;
  outline: 0px !important;
}

.changepassword_btn {
  width: 32%;
  background: #555555 !important;
  color: white !important;
  border-radius: 20px;
  font-family: "Montserrat";
}
/*--------------------------------------------------------------
# Device page
--------------------------------------------------------------*/

#demo {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.green {
  background-color: #6fb936;
}

.thumb {
  margin-bottom: 30px;
}

.page-top {
  margin-top: 85px;
}

.zoom {
  width: 100%;
  height: auto;
  min-height: 370px;
  border-radius: 10px;
  object-fit: cover;
  border: 2px solid black;
  background-color: white;
}

.zoom_add {
  width: 100%;
  max-height: auto;
  border-radius: 10px;
  object-fit: cover;
  border: 2px solid black;
}

.modal-header {
  border-bottom: none;
}

.modal-title {
  color: #000;
}

.modal-footer {
  display: none;
}

.btn-link {
  color: #fff;
}

.zoom_content {
  text-align: center;
}

.zoom_footer_connect {
  background-color: #bef8b1;
  text-align: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  position: relative;
  top: 8px;
  color: black;
}

.zoom_footer_disconnect {
  background-color: #f38282;
  text-align: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  position: relative;
  top: 8px;
}

.add_device_model_settings {
  width: 100%;
  height: auto;
  background-color: #f2f2f2;
  color: black;
  font-size: xx-small;
  text-align: center;
}

.add_device_model_settings i {
  font-size: 15px;
}

.instruction_btn {
  width: 40%;
  background: #fff !important;
  color: black !important;
  border-radius: 20px;
  font-family: "Montserrat";
  border: 1px solid black;
}

.dot_svg {
  float: right;
}

.wifi_svg {
  position: relative;
}

.textblue {
  color: #27326d;
}

.img-fluid {
  height: 230px;
}

.color-grey {
  color: #555555;
}

.btnadd {
  background-color: #263070;
  /* Blue background */
  border: none;
  /* Remove borders */
  color: white;
  /* White text */
  padding: 12px 16px;
  /* Some padding */
  font-size: 16px;
  /* Set a font size */
  cursor: pointer;
  /* Mouse pointer on hover */
  float: right;
}
/*--------------------------------------------------------------
# Dashboard page
--------------------------------------------------------------*/

.btnrefresh {
  background-color: #263070;
  /* Blue background */
  border: none;
  /* Remove borders */
  color: white;
  /* White text */
  padding: 8px 15px;
  /* Some padding */
  font-size: 16px;
  /* Set a font size */
  cursor: pointer;
  /* Mouse pointer on hover */
  float: right;
  border-radius: 10px;
}

.btnrefresh_viewa {
  background-color: #263070;
  /* Blue background */
  border: none;
  /* Remove borders */
  color: white;
  /* White text */
  padding: 8px 15px;
  /* Some padding */
  font-size: 16px;
  /* Set a font size */
  cursor: pointer;
  /* Mouse pointer on hover */
  float: right;
  border-radius: 10px;
}

.btnadd:hover {
  background-color: white;
  color: #263070;
  border: 1px solid #263070;
}

.btnrefresh:hover {
  background-color: white;
  color: #263070;
  border: 1px solid #263070;
}

.btnrefresh_viewa:hover {
  background-color: white;
  color: #263070;
  border: 1px solid #263070;
}
/*--------------------------------------------------------------
# View attendence  page buttons
--------------------------------------------------------------*/

@media (min-width: 265px) and (max-width: 500px) {
  .btnrefresh_viewa {
    padding: 6px 10px;
    margin-bottom: 2px;
  }
}

@media (min-width: 650px) and (max-width: 800px) {
  .btnrefresh_viewa {
    padding: 6px 10px;
    margin-bottom: 2px;
  }
}

@media (min-width: 200px) and (max-width: 1900px) {
  .viewcontainer {
    flex: 0 auto !important;
  }
}
/*--------------------------------------------------------------
# View attendence  page dropdown
--------------------------------------------------------------*/

.user {
  background-color: #263070 !important;
  color: white !important;
}
/* @media(min-width:265px) and (max-width:500px) {
      .user {
          width: 70%;
      }
  }
  
  @media(min-width:700px) and (max-width:850px) {
      .user {
          width: 69%;
      }
  } */

.dropdown-item {
  color: #263070 !important;
}
/*--------------------------------------------------------------
# View attendence  datatables
--------------------------------------------------------------*/

.page-link {
  color: #263070 !important;
}

.page-item.active .page-link {
  background-color: #263070 !important;
  border-color: #263070 !important;
  color: white !important;
}
/* .topbarbutton #sidebarToggleTop {
        height: 2.375rem;
        box-shadow: 0 0 !important;
    } */

.extra {
  position: absolute;
  bottom: 150px;
}

.brandtext {
  color: white;
  font-size: 1.3rem;
}

.hygiene_allstars {
  background-color: #eda124;
  color: white;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.hygiene_heroes {
  background-color: #5271c1;
  color: white;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.box1 {
  background-color: #4ca539;
  color: white;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.colorblue {
  color: #263070;
}

.dropdown-item-custom {
  color: white !important;
}
/* Comming Soon page */

.csimg {
  max-width: 30%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.csimgdiv {
  display: grid;
}

.colorblue {
  color: #263070;
}

.btn-facebook {
  color: #fff;
  background-color: #f04032;
  border-color: #fff;
  -webkit-box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
  border-radius: 0.25rem;
}

.btn-facebook:hover {
  color: #fff;
  background-color: #f04032;
  border-color: #e6e6e6;
}

.btn-google {
  color: #fff;
  background-color: #50e96a;
  border-color: #bdbdbd;
  -webkit-box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
  border-radius: 0.25rem;
}

.btn-google:hover {
  color: #fff;
  background-color: #50e96a;
  border-color: #434343;
}

.example-handle {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #ccc;
  cursor: move;
  width: 24px;
  height: 24px;
}

.example-boundary {
  width: 400px;
  height: 400px;
  max-width: 100%;
  border: dotted #ccc 2px;
}

.cube {
  cursor: grabbing;
}

@media (min-width: 200px) and (max-width: 460px) {
  span.addbutton {
    display: none;
  }
  span.addicon {
    display: inline-block;
  }
}

@media (min-width: 461px) {
  span.addicon {
    display: none;
  }
}

.pwd-custom {
  font-size: 2rem;
  font-family: auto;
}

@media (min-width: 200px) and (max-width: 280px) {
  .card-header {
    overflow-x: auto !important;
    flex-wrap: nowrap !important;
  }
  #viewdata {
    margin-left: 100px !important;
    padding: 5px 30px;
  }
  .rangeform {
    margin-right: 200px;
  }
  .headerbuttons {
    max-width: 100% !important;
    display: inline-block !important;
  }
}

@media (min-width: 281px) and (max-width: 500px) {
  .card-header {
    overflow-x: auto !important;
    flex-wrap: nowrap !important;
  }
  #viewdata {
    margin-left: 50px !important;
    padding: 5px 30px;
  }
  .rangeform {
    margin-right: 200px;
  }
}
#viewdata_report {
  /* margin-left: 50px !important; */
  padding: 5px 30px;
  float: left !important;
}
@media (min-width: 501px) and (max-width: 790px) {
  .card-header {
    overflow-x: auto !important;
    flex-wrap: nowrap !important;
  }
  #viewdata {
    margin-left: 50px !important;
    padding: 5px 30px;
  }
  .rangeform {
    margin-right: 200px;
  }
  .headerbuttons {
    max-width: 40% !important;
  }
}

@media (min-width: 530px) and (max-width: 5500px) {
  .card-header {
    overflow-x: auto !important;
    flex-wrap: nowrap !important;
  }
  #viewdata {
    margin-left: 50px !important;
    padding: 5px 30px;
  }
  .rangeform {
    margin-right: 200px;
  }
  .headerbuttons {
    max-width: 65% !important;
  }
}

@media (min-width: 791px) and (max-width: 900px) {
  .card-header {
    overflow-x: auto !important;
    flex-wrap: nowrap !important;
  }
  #viewdata {
    margin-left: 50px !important;
    padding: 5px 30px;
  }
  .rangeform {
    margin-right: 200px;
  }
  .headerbuttons {
    max-width: 60% !important;
  }
}

@media (min-width: 900px) and (max-width: 1050px) {
  .card-header {
    overflow-x: auto !important;
    flex-wrap: nowrap !important;
  }
  #viewdata {
    margin-left: 50px !important;
    padding: 5px 30px;
  }
  .rangeform {
    margin-right: 200px;
  }
  .headerbuttons {
    max-width: 60% !important;
  }
}

.displayend {
  display: flex;
  justify-content: end;
}

@media (min-width: 200px) and (max-width: 700px) {
  .displayend {
    display: inline;
  }
}

.pushup {
  margin-top: -11px;
}

input.ng-invalid.ng-dirty {
  border-color: #e91e63 !important;
  box-shadow: 0 1px 0 0 #e91e63 !important;
}

.createaccount_btn:hover {
  border: 2px solid white;
}

@media (min-width: 300px) and (max-width: 400px) {
  .card-body {
    padding: 0.25rem !important;
  }
}

#rounded {
  border-radius: 10px;
}

@media (min-width: 270px) and (max-width: 550px) {
  #connect {
    width: 200%;
  }
}

@media (min-width: 270px) and (max-width: 550px) {
  #remove {
    width: 200%;
  }
}

.createaccount_btn:active {
  border: 2px solid white;
  background-color: midnightblue !important;
  color: white !important;
}
.createaccount_btn:focus {
  background-color: midnightblue !important;
  color: white !important;
}
.login_btn:active {
  border: 2px solid white;
  background-color: midnightblue !important;
  color: white !important;
}
.login_btn:focus {
  background-color: midnightblue !important;
  color: white !important;
}
.edit_btn:active {
  border: 2px solid white;
  background-color: midnightblue !important;
  color: white !important;
}
.edit_btn:focus {
  background-color: midnightblue !important;
  color: white !important;
}

@media (min-width: 1901px) and (max-width: 3050px) {
  .viewcontainer {
    flex: 0 auto !important;
  }
}

.router-link-active {
  background-color: lightgray;
}

.login-bg {
  background-image: url("../img/microgo_posters/login.png");
  background-size: cover;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-row {
  margin: 0%;
}

.custom-btn {
  padding: 5px 30px;
  float: left !important;
}

.bg-green {
  background-color: #9cb66b !important;
}

.bg-red {
  background-color: #c86b4f !important;
}

.ng-image-slider .ng-image-slider-container .main .main-inner {
  width: unset !important;
  padding: 0;
  height: 100%;
}
