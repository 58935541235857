/*--------------------------------------------------------------
#Body css
--------------------------------------------------------------*/

a {
    color: #106eea;
}

a:hover {
    color: #3b8af2;
    text-decoration: none;
}


/* h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Roboto", sans-serif;
} */


/*--------------------------------------------------------------
# Counts for lobby and login
--------------------------------------------------------------*/

.counts {
    padding: 100px 0 60px;
}

.counts .count-box {
    padding: 30px 30px 25px 30px;
    width: 35%;
    height: 430px;
    display: block;
    margin: auto;
    position: relative;
    text-align: center;
    background: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
}

.counts .count-box img {
    position: absolute;
    top: -72px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 24px;
    background: #73a7ce;
    /* padding: 30px; */
    color: #fff;
    border-radius: 50%;
    border: 1px solid #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.counts .count-box span {
    font-size: 36px;
    display: block;
    font-weight: normal;
    color: black;
    margin: auto;
    font-family: 'Montserrat';
}

.counts .count-box p {
    padding: 0;
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: black;
}

.smalltxtchckbox {
    padding: 0;
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: black;
}


/*--------------------------------------------------------------
# Counts for experienceGo assure page
--------------------------------------------------------------*/

.counts_ex {
    padding: 100px 0 60px;
}

.counts_ex .count-box_ex {
    padding: 30px 30px 25px 30px;
    width: 45%;
    height: 500px;
    display: block;
    margin: auto;
    position: relative;
    text-align: center;
    background: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
}

.counts_ex .count-box_ex img {
    position: absolute;
    top: -72px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 24px;
    background: #73a7ce;
    /* padding: 30px; */
    color: #fff;
    border-radius: 50%;
    border: 1px solid #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.counts_ex .count-box_ex span {
    font-size: 36px;
    display: block;
    font-weight: normal;
    color: black;
    margin: auto;
    font-family: 'Montserrat';
}

.counts_ex .count-box_ex p {
    padding: 0;
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: white;
}


/*--------------------------------------------------------------
# Page Footer for lobby and login
--------------------------------------------------------------*/

.page-footer {
    color: black;
    font-family: 'Montserrat';
    font-weight: bolder;
}


/*--------------------------------------------------------------
# Page Footer for expierence Goassure
--------------------------------------------------------------*/

.page-footer_ex {
    color: black;
    font-family: 'Montserrat';
    font-weight: bolder;
    margin-top: -50px;
}


/*--------------------------------------------------------------
# Forms css for lobby and login
--------------------------------------------------------------*/

::placeholder {
    color: black !important;
    opacity: 1;
    font-family: 'Montserrat';
}

.form_container {
    margin-top: 100px;
}

.login_btn {
    width: 70%;
    background: #000000 !important;
    color: white !important;
    border-radius: 20px;
    font-family: 'Montserrat';
}

@media(min-width:200px) and (max-width:300px) {
    .login_btn {
        width: auto;
    }
}

@media(min-width:301px) and (max-width:400px) {
    .login_btn {
        width: 120%;
    }
}

.lobby_btn {
    width: 100%;
    background: #ffffff !important;
    color: black !important;
    border-radius: 8px;
    font-family: 'Montserrat';
    border: black 1.5px solid;
    font-weight: bold;
}

.lobby_btn {
    box-shadow: none !important;
    outline: 0px !important;
}

@media(min-width:340px) and (max-width: 500px) {
    .lobby_btn {
        width: 135%;
        font-size: 15px;
    }
}

@media(min-width:300px) and (max-width: 339px) {
    .lobby_btn {
        width: 145%;
        font-size: 14.5px;
    }
}

@media(min-width:200px) and (max-width: 299px) {
    .lobby_btn {
        width: 150%;
        font-size: 11.5px;
    }
}

.lobby_btn:active,
.lobby_btn:focus,
.lobby_btn:hover {
    border: 2px solid black;
}

.login_btn:focus {
    box-shadow: none !important;
    outline: 0px !important;
}

.login_btn:active,
.login_btn:focus,
.login_btn:hover {
    border: 2px solid white;
}

.login_container {
    padding: 0 2rem;
}

.input-group-text {
    background: #000000 !important;
    color: white !important;
    border: 0 !important;
    border-radius: 20px 0px 0px 20px !important;
}

.input_user,
.input_pass:focus {
    box-shadow: none !important;
    outline: 0px !important;
}

.input_user:focus,
.input_user:hover,
.input_user:active,
.input_pass:focus,
.input_pass:active,
.input_pass:hover {
    border: 2px solid black !important;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #000000 !important;
}

.form-control {
    border-radius: 6px;
}

.loginimg {
    width: 700px;
    height: 680px;
}


/*--------------------------------------------------------------
# Button css for expierence Goassure
--------------------------------------------------------------*/

.signup_btn:focus {
    box-shadow: none !important;
    outline: 0px !important;
}

.signup_btn {
    width: 70%;
    background: #000000 !important;
    color: white !important;
    border-radius: 20px;
    font-family: 'Montserrat';
}


/*--------------------------------------------------------------
# Button css for createaccount
--------------------------------------------------------------*/

.createaccount_btn:focus {
    box-shadow: none !important;
    outline: 0px !important;
}

.createaccount_btn {
    width: 70%;
    background: #000000 !important;
    color: white !important;
    border-radius: 20px;
    font-family: 'Montserrat';
}

@media(min-width:200px) and (max-width:300px) {
    .createaccount_btn {
        width: 100%;
    }
}


/*--------------------------------------------------------------
# Media Query for lobby and login
--------------------------------------------------------------*/

@media (max-width: 768px) {
    #footer .copyright,
    #footer .credits {
        float: none;
        text-align: center;
        padding: 2px 0;
    }
    .counts .count-box {
        width: 70%;
    }
}

@media (min-width: 360px) and (max-width: 460px) {
    .counts .count-box {
        width: 70%;
        height: 370px;
    }
}

@media (min-width: 768px) and (max-width: 868px) {
    .counts .count-box {
        width: 60%;
        height: 370px;
    }
}


/*--------------------------------------------------------------
# Media Query for expierence Goassure
--------------------------------------------------------------*/

@media (max-width: 768px) {
    #footer .copyright,
    #footer .credits {
        float: none;
        text-align: center;
        padding: 2px 0;
    }
    .counts_ex .count-box_ex {
        width: 70%;
    }
}

@media (min-width: 260px) and (max-width: 460px) {
    .counts_ex .count-box_ex {
        width: 91%;
        height: 534px;
    }
    .counts_ex .count-box_ex p {
        margin-top: -6px;
        font-size: 13px;
    }
    .signup_btn {
        width: 60%;
    }
}

@media (min-width: 669px) and (max-width: 900px) {
    .counts_ex .count-box_ex {
        width: 80%;
        height: 550px;
    }
    .counts_ex .count-box_ex p {
        margin-top: -6px;
        font-size: 13px;
    }
    .signup_btn {
        width: 65%;
    }
}


/*--------------------------------------------------------------
# Media Query for create account
--------------------------------------------------------------*/

@media (min-width: 360px) and (max-width:460px) {
    /* .input_user_createaccleft{
    margin-right: 0px;
  }
  .input_user_createaccright{
    margin-left: 0px;
  } */
}